import React from "react";
import { useParams, Link } from "react-router-dom";
import "../css/catedetails.css";
import productData from "../Datas/productData";
import Footer from "./Footer";
import Header from "./Header";

function CateDetails() {
  const { cateId } = useParams();

  const filteredProducts = productData.filter(
    (product) => product.cateId === parseInt(cateId)
  );

  const columns = filteredProducts.length > 0 ? filteredProducts.length : 1;
  const gridTemplateColumns = `repeat(${columns},1fr)`;

  return (
    <>
      <Header />
      <div className="products-grid">
        <div className="products-grid-inner" style={{ gridTemplateColumns }}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div className="cateDetails" key={product.id}>
                <Link to={`/shopDetails/${product.id}`}>
                  <img alt={product.name} src={product.images[0]} />
                  <h3>{product.name}</h3>
                  <p>
                    From:
                    <br />
                    <strong>{product.price}</strong> (Incl. VAT) per pack
                  </p>
                  <h6>{product.description}</h6>
                </Link>
              </div>
            ))
          ) : (
            <p>No products available for this category.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CateDetails;
