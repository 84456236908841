const category = [
  {
    cateId: 1,
    name: "Retail Labels",
    images: ["/cata/a.jpg"],
  },
  {
    cateId: 2,
    name: "Identification & Protection Labels",
    images: ["/cata/b.jpg"],
  },
  {
    cateId: 3,
    name: "Service & Inspection Labels",
    images: ["/cata/c.jpg"],
  },
  {
    cateId: 4,
    name: "Health & Safety Labels",
    images: ["/cata/d.jpg"],
  },
  {
    cateId: 5,
    name: "Warning Labels",
    images: ["/cata/e.jpg"],
  },
  {
    cateId: 6,
    name: "Catering & Hospitality Labels",
    images: ["/cata/f.jpg"],
  },
  {
    cateId: 7,
    name: "Handling & Shipping Labels",
    images: ["/cata/g.jpg"],
  },
  {
    cateId: 8,
    name: "General Labels",
    images: ["/cata/h.jpg"],
  },
];

export default category;
