import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const CheckOut = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [add3, setAdd3] = useState("");
  const [company, setCompany] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState("US");

  const StyledTableContainer = styled(TableContainer)({
    minWidth: 600,
    backgroundColor: "#f5f5f5",
    boxShadow: "none",
  });

  const StyledImage = styled("img")({
    width: 112,
    height: 124,
    objectFit: "cover",
  });

  const StyledTableCell = styled(TableCell)({
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    fontSize: "16px",
    borderRight: "1px dotted #ccc",
  });

  const handlePlaceOrder = () => {
    const newErrors = {};
    const cardInputs = document.querySelectorAll(
      'select[name="selected"], input[name="email"], input[name="firstName"],input[name="lastName"], input[name="add1"], input[name="add2"], input[name="add3"],input[name="postalCode"], input[name="city"],input[name="phone"]'
    );
    cardInputs.forEach((input) => {
      if (input.tagName === "SELECT" || input.tagName === "INPUT") {
        if (!input.value) {
          input.style.border = "2px solid red";
        } else {
          input.style.border = "";
        }
      }
    });

    if (!email) newErrors.email = "Email is required";
    if (!selected) newErrors.selected = "Country is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!add1) newErrors.add1 = "Address line 1 is required";
    if (!add2) newErrors.add2 = "Address line 2 is required";
    if (!add3) newErrors.add3 = "Address line 3 is required";
    if (!postalCode) newErrors.postalCode = "Postal code is required";
    if (!city) newErrors.city = "City is required";
    if (!phone) newErrors.phone = "Phone is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      alert("Order placed!");
      navigate("/paysuccess");
      clearCart();
    }
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price.replace("£", ""));
    return price * quantity + quantity * 0.2; // 计算总价
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="check-custom-checkout-page">
          <div className="left12">
            <div className="step-title">Shipping Address</div>
            <div className="check-order-info">
              <div>
                <label className="label__div">
                  Email Address<div className="require_div">*</div>
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <p className="error">{errors.email}</p>}
                <p className="p__text">
                  You can create an account after checkout.
                </p>
              </div>

              <div>
                <label className="label__div">
                  First Name<div className="require_div">*</div>
                </label>
                <br />
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && (
                  <p className="error">{errors.firstName}</p>
                )}
              </div>
              <div>
                <label className="label__div">
                  Last Name<div className="require_div">*</div>
                </label>
                <br />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && <p className="error">{errors.lastName}</p>}
              </div>
              <div>
                <label className="label__div">Company</label>
                <br />
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
              <div>
                <label className="label__div">
                  Street Address<div className="require_div">*</div>
                </label>
                <br />
                <input
                  type="text"
                  id="add1"
                  name="add1"
                  value={add1}
                  onChange={(e) => setAdd1(e.target.value)}
                />
                {errors.add1 && <p className="error">{errors.add1}</p>}
                <input
                  type="text"
                  id="add2"
                  name="add2"
                  value={add2}
                  onChange={(e) => setAdd2(e.target.value)}
                />
                {errors.add2 && <p className="error">{errors.add2}</p>}
                <input
                  type="text"
                  id="add3"
                  name="add3"
                  value={add3}
                  onChange={(e) => setAdd3(e.target.value)}
                />
                {errors.add3 && <p className="error">{errors.add3}</p>}
              </div>
              <div>
                <label className="label__div">
                  Country<div className="require_div">*</div>
                </label>
                <br />
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => {
                    setSelected(code);
                  }}
                  value={selected}
                  name="selected"
                  className="check-select"
                />
                {errors.selected && <p className="error">{errors.selected}</p>}
              </div>
              <div>
                <label className="label__div">
                  City<div className="require_div">*</div>
                </label>
                <br />
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                {errors.city && <p className="error">{errors.city}</p>}
              </div>
              <div>
                <label className="label__div">
                  Zip/Postal Code<div className="require_div">*</div>
                </label>
                <br />
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
                {errors.postalCode && (
                  <p className="error">{errors.postalCode}</p>
                )}
              </div>
              <div>
                <label className="label__div">
                  Phone Number<div className="require_div">*</div>
                </label>
                <br />
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {errors.phone && <p className="error">{errors.phone}</p>}
              </div>
              <button
                className="check-checkout-button"
                onClick={handlePlaceOrder}
              >
                Pay Now
              </button>
            </div>
          </div>

          <div className="right">
            <div className="check-order-summary">
              <h2>Order Summary</h2>
              <h2 style={{ textTransform: "none", marginTop: "-3%" }}>
                <strong>{cartItems.length}&nbsp;Item in Basket</strong>
              </h2>
              <div className="cart-items">
                {cartItems.length === 0 ? (
                  <h3 style={{ color: "black", textAlign: "center" }}>
                    Your Cart is Empty!
                  </h3>
                ) : (
                  <StyledTableContainer component={Paper}>
                    <Table aria-label="shopping cart table">
                      <TableBody>
                        {cartItems.map((item, index) => {
                          const product = getProductById(item.id);
                          return (
                            <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                <StyledImage
                                  src={product.images[0]}
                                  alt="Product"
                                  style={{
                                    paddingRight: "30px",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "grid",
                                  }}
                                >
                                  {product.name}
                                  <br />
                                  {item.adhesive && (
                                    <span>
                                      <strong>Adhesive:</strong>&nbsp;
                                      {item.adhesive}
                                    </span>
                                  )}

                                  {item.size && (
                                    <span>
                                      <strong>Size:</strong>&nbsp;
                                      {item.size}
                                    </span>
                                  )}
                                  {item.color && (
                                    <span>
                                      <strong>Color:</strong>&nbsp;
                                      {item.color}
                                    </span>
                                  )}
                                  <span>
                                    <strong>Part No:</strong>&nbsp;
                                    {product.partNo}
                                  </span>
                                </div>
                              </StyledTableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                }}
                              >
                                £{product.price}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.quantity}
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ verticalAlign: "middle" }}
                              >
                                £
                                {calculateItemTotal(
                                  item.id,
                                  item.quantity
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CheckOut;
