import "./App.css";
import Main from "./Component/Main";
import ContactUs from "./Component/ContactUs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./Component/AboutUs";
import Privacy from "./Component/Privacy";
import ProductDetail from "./Component/ProductDetail";
import CartPage from "./Component/CartPage";
import CheckOut from "./Component/CheckOut";
import { CartProvider } from "./Component/CartContext";
import PaySuccess from "./Component/SuccessPay";
import CateDetails from "./Component/CateDetails";
import Terms from "./Component/Terms";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/shopDetails/:id" element={<ProductDetail />} />
          <Route path="/cateDetails/:cateId" element={<CateDetails />} />
          <Route path="/cartpage" element={<CartPage />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/paysuccess" element={<PaySuccess />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
