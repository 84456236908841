import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function AboutUs() {
  const [isShopContentVisible, setIsShopContentVisible] = useState(false);

  const toggleShopContentVisibility = () => {
    setIsShopContentVisible(!isShopContentVisible);
  };
  return (
    <div className="main-container">
      <Header />
      <div className="contactDiv">
        <div className="breadcrumbs-container">
          <p>Home / About Us</p>
          <h1>About Us</h1>
        </div>
        <div className="c-overview-bg">
          <div className="c-overview-bg-inner">
            <h1>Welcome to Labels Online</h1>
            <p>
              Having been in the self-adhesive label industry since 1974, we
              have developed the biggest range of stock labels, stickers, and
              tags in the UK all available to purchase online today!
            </p>
            <p>
              Serving a diverse customer base of over 100,000 customers, our
              carefully designed stock products provide a cost-effective and
              timely solution to a wide range of labeling requirements. Situated
              in the heart of the country, all products are produced in-house to
              strict ISO 9001:2015 quality control requirements.
            </p>
            <p className="c-overview-bg__info">
              Where a bespoke label design is required, we offer the complete
              design and print service, contact our team to discuss your
              requirements.
            </p>
          </div>
        </div>
        <div className="block-static-block">
          <div className="block-static-block-inner">
            <h3>Our Services</h3>
            <div className="service-box">
              <div className="c-article-overview">
                <img alt="" src="/head/service1.jpg" />
                <div className="service-txt">
                  <span>Stock Labels, Stickers & Tags</span>
                  <p>
                    We stock a huge range of pre-printed labels, stickers, and
                    tags for every industry, need, and desire available to
                    purchase online with fast delivery and unbeatable prices.
                  </p>
                </div>
              </div>
              <div className="c-article-overview">
                <img alt="" src="/head/service2.jpg" />
                <div className="service-txt">
                  <span>Custom Labels</span>
                  <p>
                    We offer the complete design and print service for custom
                    printed labels. With over 45 years' experience in the label
                    industry, we have the technical knowledge and resources to
                    accommodate any enquiry.
                  </p>
                </div>
              </div>
              <div className="c-article-overview">
                <img alt="" src="/head/service3.jpg" />
                <div className="service-txt">
                  <span>Customer Service</span>
                  <p>
                    Our dedicated team of customer service advisors are on-hand
                    to offer help and solutions to all your queries. We
                    endeavour to respond to email enquiries within 2 working
                    hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shop-img">
        <div className="title-wrapper">
          <div className="title-wrapper-inner">
            <h2 onClick={toggleShopContentVisibility}>Why Shop With Us?</h2>
          </div>
        </div>
        {isShopContentVisible && (
          <div className="shop-content">
            <div className="shop-content-inner">
              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop1.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">
                  ISO 9001:2015 Accreditation
                </div>
              </div>
              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop2.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">Worldwide Delivery</div>
              </div>
              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop3.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">
                  Exceptional Customer Service
                </div>
              </div>

              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop4.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">Trusted UK Manufacturer</div>
              </div>
              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop5.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">
                  Free Delivery on Orders Over £150
                </div>
              </div>
              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop6.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">Custom Labels</div>
              </div>

              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop7.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">
                  Public Sector Accounts Welcome
                </div>
              </div>
              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop7.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">Lowest Prices Guaranteed</div>
              </div>
              <div className="c-whyshop__item">
                <div className="c-whyshop__icon-wrapper">
                  <img
                    alt=""
                    src="/head/shop8.png"
                    className="c-whyshop__icon-image"
                  />
                </div>
                <div className="c-whyshop__label">Sample Packs</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="c-footer">
        <div className="c-footer-inner">
          <h2>What people say about us</h2>
          <div className="tp-widget-wrapper">
            <img alt="" src="/head/about.png" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
