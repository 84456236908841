import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="foot-main">
          <div className="botLeft">
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/aboutUs" onClick={toTop}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toTop}>
                  Privacy & Cookies
                </Link>
              </li>
              <li>
                <Link to="/terms" onClick={toTop}>
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="botLeft">
            <h4>Contact Us</h4>
            <ul>
              <li>01299 250 981</li>
              <li>time@ogeeosae.xyz</li>
              <li>Monday- Friday 8.30am-5pm</li>
            </ul>
          </div>
        </div>

        <div className="foot-sec">
          <div className="foot-sec-inner">
            <div className="clearfix">
              <ul className="c-footer__hlist">
                <li>© Labels Online 2024</li>
                <li>Sitemap</li>
                <li>Website by Absolute</li>
              </ul>
            </div>
          </div>
          <div className="foot-sec-inner-right">
            <div className="clearfix">
              <ul className="c-footer__hlist">
                <li>Pay securely with:</li>
                <li className="li-two">
                  <img alt="" src="/head/card1.png" />
                  <img alt="" src="/head/card2.png" />
                  <img alt="" src="/head/card3.png" />
                  <img alt="" src="/head/card4.png" />
                  <img alt="" src="/head/card5.png" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
