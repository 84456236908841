import React from "react";
import { Link } from "react-router-dom";
import "../css/category.css";
import category from "../Datas/category";

function Category() {
  return (
    <div className="c-category-blocks__wrapper">
      <h2>Shop by Category</h2>
      <div className="c-category-blocks__wrapper-inner">
        {category.map((cate) => (
          <div className="cateTop" key={cate.cateId}>
            <Link to={`/cateDetails/${cate.cateId}`}>
              <img alt={cate.name} src={cate.images[0]} />
              <h3>{cate.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Category;
