import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Terms() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactDiv">
        <div className="breadcrumbs-container">
          <p>Home / Terms and Conditions</p>
          <h1>Terms and Conditions</h1>
        </div>
      </div>
      <div className="cms-content">
        <div className="cms-content-inner">
          <ol>
            <li>
              <strong>Definitions</strong>
            </li>
            <li style={{ listStyleType: "none" }}>
              <ol>
                <li>
                  “the Company” is Daymark Ltd, trading as Labelsonline or as
                  Daymark Labels.&nbsp; www.labelsonline.co.uk or
                  www.daymark.co.uk
                </li>
                <li>
                  “the Customer” is any party entering into a contract with the
                  Company for the supply, design or production of labels (“the
                  goods”).
                </li>
                <li>
                  “Goods” includes the production of test or sample items and
                  proofs irrespective of whether the Customer places a
                  subsequent production order.
                </li>
                <li>
                  Delivery of goods shall be deemed to take place when such
                  goods are signed for by or on behalf of the Customer or, where
                  delivery is by standard postage, on posting.
                </li>
                <li>
                  “Act of Insolvency”: means:
                  <ol>
                    <li>
                      the Customer having any distress, execution or other
                      insolvency process levied upon it; or
                    </li>
                    <li>
                      the Customer making, or offering to make, any arrangements
                      or compromise with creditors, or committing any act of
                      bankruptcy; or
                    </li>
                    <li>
                      the Customer having any petition or receiving order in
                      bankruptcy presented or make against it.
                    </li>
                    <li>
                      the Customer having a receiver of its property or assets
                      or any part of them appointed;
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>General</strong>
            </li>
            <ol>
              <li>
                These Terms and Conditions apply to every contract between the
                Company and any Customer and to all further contracts between
                the Company and that Customer (until and unless the Company
                notifies that Customer of revised Terms and Conditions).
              </li>
              <li>
                No prior statements made by the Company or the Customer, nor any
                correspondence between them, are incorporated into any such
                contract unless separately agreed in writing by the Company.
              </li>
              <li>
                No verbal price estimate made prior to receipt of full order
                details will be binding upon the Company.
              </li>
              <li>
                No representation by or on behalf of the Company by an employee
                or agent (including but not limited to advice or recommendations
                as to the quality or suitability for specific purposes of the
                Company’s goods) takes effect unless separately confirmed by the
                Company in writing and the Customer agrees that it does not
                enter into any contract in reliance on such representations
                unless so confirmed.
              </li>
              <li>
                Although the Company seeks to ensure that any description or
                specification of goods provided online or via printed material
                is accurate, it does not warrant the accuracy of such
                information, and any implied term that a sale of goods is a sale
                by sample or description is expressly excluded.
              </li>
              <li>
                The Company will endeavour to deliver the quantity of goods
                ordered, but the Customer acknowledges and accepts that
                quantities may vary from those specified by up to 10% under or
                over.
              </li>
              <li>
                The Customer acknowledges and accepts that:
                <ol>
                  <li>
                    any variation in quantity within the scope of Clause 2(f)
                    will give rise to a price increase or reduction pro-rata.
                  </li>
                  <li>
                    Any such price increase will fall payable under Clause 4(b);
                  </li>
                  <li>
                    Any such price reduction will be by means of credit note to
                    the Customer.
                  </li>
                </ol>
              </li>
              <li>
                The Customer acknowledges and accepts that the Company may:
                <ol>
                  <li>
                    make such changes to the specification of its goods as are
                    required to comply with applicable safety or statutory
                    requirements;
                  </li>
                  <li>
                    increase its prices after the contract is agreed to reflect
                    any increase in the price it is charged by its suppliers for
                    goods or materials.
                  </li>
                </ol>
              </li>
              <li>
                Other than in accordance with these terms and conditions, no
                variation to any contract takes effect unless agreed in writing
                by a director of the Company.
              </li>
              <li>
                Any agreed variation in the scope of work to be carried out
                under a contract (including but not limited to changes in
                quantity, and changes to design or proofs as requested by the
                Customer) will be charged for by the Company at the sum agreed
                between the parties for such variation or, in default of such
                agreement on price, at the Company’s prevailing rates.
              </li>
              <li>
                No waiver by the Company of any of these Terms and Conditions or
                forbearance to enforce any remedy it is entitled to shall
                prejudice any of the Company’s other rights and remedies or
                operate as a waiver of any other breach by the Customer under
                any contract with the Company.
              </li>
              <li>
                Should any of these Term or Conditions be held to be invalid or
                unenforceable in whole or in part the validity and
                enforceability of the remaining part and of the other Terms and
                Conditions shall not be affected.
              </li>
              <li>
                The Customer may not assign the benefit of any contract with the
                Company without the written consent of the Company.
              </li>
              <li>
                Nothing in these terms and conditions shall confer any right
                upon a third party and the Customer agrees that the operation of
                the Contracts (Rights of Third Parties) Act 1999 is excluded
                from any contract to which these terms and conditions apply.
              </li>
            </ol>
            <li>
              <strong>Credit</strong>
            </li>
            <ol>
              <li>
                Any grant of credit will be at the sole discretion of the
                Company and will be subject to the Customer providing a
                fully-completed application form and to the Customer’s
                references being taken up and reviewed.
              </li>
              <li>
                The provision of credit by the Company to the Customer is
                subject to the Customer’s compliance with Clause 4(b) and may be
                reviewed or withdrawn by the Company at any time and at the
                Company’s sole discretion.
              </li>
              <li>
                Credit accounts will be reviewed periodically and accounts that
                have not traded for 6 months may have credit suspended or
                withdrawn.
              </li>
              <li>
                If credit is withdrawn for any reason the Company shall be
                entitled to any or all of the remedies under Clause 4(f).
              </li>
            </ol>
            <li>
              <strong>Payment</strong>
            </li>
            <ol>
              <li>
                Unless credit is granted, payment for goods is due immediately
                upon order. Where such payment is made by credit card, PayPal or
                other such third-party payment service, the Company will not
                fulfil the order until authorisation of payment has been
                confirmed.
              </li>
              <li>
                All credit invoices are strictly net for payment within 30 days
                of date of invoice and time of payment shall be of the essence
                of the contract.
              </li>
              <li>
                All prices are exclusive of VAT and the Customer must pay such
                tax in addition to and at the same time as payment for goods.
              </li>
              <li>
                All list prices are exclusive of delivery which will be quoted
                for as a separate item or, where goods are sold by the Company’s
                website, calculated and added before payment is taken. Delivery
                charges and times may vary depending on the location to which
                goods are to be consigned.
              </li>
              <li>
                Where goods are sold by instalments each instalment shall be
                invoiced and paid for separately.
              </li>
              <li>
                If for any reason whatsoever payment is not made when due the
                Company shall be entitled to:
                <ol>
                  <li>
                    for commercial contracts, charge statutory interest and late
                    payment fees;
                  </li>
                  <li>
                    for non-commercial contracts, charge interest at the rate of
                    8% per annum;
                  </li>
                  <li>cancel or suspend any contract with the Customer;</li>
                  <li>
                    require the Customer to pay any other sums due to the
                    Company immediately;
                  </li>
                  <li>
                    enter into the premises of the Customer and repossess and
                    remove all of its goods that remain its property under
                    clause 7.
                  </li>
                </ol>
              </li>
              <li>
                The Customer agrees and acknowledges that:
                <ol>
                  <li>
                    The Customer shall not be entitled to withhold payment by
                    reason of set-off or counterclaim in respect of any claim
                    disputed by the Company.
                  </li>
                  <li>
                    The Company shall be entitled in the event of the insolvency
                    of the Customer to set off against any sum owed by the
                    Company to the Customer the full amount of any sums owed by
                    the Customer to the Company.
                  </li>
                </ol>
              </li>
              <li>
                The Customer agrees to indemnify the Company in respect of the
                full amount of any fees costs disbursements or expenses incurred
                (including court fees and the reasonable cost of legal
                representation) arising from overdue payment by the Customer or
                any other breach by the Customer of these terms and conditions.
              </li>
            </ol>
            <li>
              <strong>Termination or Suspension</strong>
            </li>
            <ol>
              <li>
                Without prejudice to any of its other rights the Company may
                terminate the contract or suspend further deliveries to the
                Customer in the event of:
                <ol>
                  <li>
                    the Customer failing to make due payment in accordance with
                    Clause 4(b) for any goods or work done; or
                  </li>
                  <li>any act of Insolvency relating to the Customer; or</li>
                  <li>
                    the Company receiving any information indicating that the
                    Customer is or may become unable to pay its debts.
                  </li>
                </ol>
              </li>
              <li>
                In the event the contract is terminated under clause 5(a) the
                Company shall be entitled to any or all of the remedies under
                clause 4(f).
              </li>
              <li>
                The Company reserves the right to withhold performance of any of
                its obligations under the contract if in its sole opinion the
                Customer’s credit status becomes unsatisfactory.
              </li>
            </ol>
            <li>
              <strong>Delivery and Storage</strong>
            </li>
            <ol>
              <li>
                Any date or time quoted for delivery is given as an estimate
                only and the Company shall:
                <ol>
                  <li>
                    not be liable for any loss or damage howsoever arising by
                    any matter beyond its reasonable control from failure to
                    deliver on or by such stated date or at such stated time;
                  </li>
                  <li>
                    if found liable for loss or damage arising from failure to
                    deliver on such stated date or at such stated time shall be
                    liable only for loss limited to the excess (if any) over the
                    price of the goods of the cost to the Customer of similar
                    goods in the cheapest available market.
                  </li>
                </ol>
              </li>
              <li>
                If the Customer causes the delivery of goods or any part thereof
                to be delayed beyond the time when they would otherwise have
                been delivered or does not make available a person authorised to
                sign for a delivery such goods shall be removed to the Company’s
                premises or other storage facility and stored by the Company at
                the sole risk and expense of the Customer.
              </li>
              <li>
                The Customer agrees that any failure of the Company to make any
                delivery (including part-delivery of an order providing for
                delivery by instalments or by call-off) shall not entitle the
                Customer to cancel or suspend any subsequent or other delivery
                or contract.
              </li>
            </ol>
            <li>
              <strong>Passing of Risk and Retention of Title</strong>
            </li>
            <ol>
              <li>
                Risk in goods supplied passes to the Customer upon delivery by
                the Company as defined at clause 1(d);
              </li>
              <li>
                Notwithstanding the provisions of Clause 7(a) as to the passing
                of risk, goods supplied by the Company shall remain its sole
                property until the Customer has paid in full the agreed price
                and all other sums due from the Customer to the Company whether
                under this Contract or otherwise (including any interest due).
                Notwithstanding such retention of title, the Company shall be
                entitled to maintain an action for the price of the goods as
                soon as payment falls due.
              </li>
              <li>
                The Customer acknowledges that it is in possession of such goods
                as bailee for the Company until the sums due in clause 7(b) have
                been paid in full or the goods have been incorporated or resold
                in the ordinary course of the Customer's business and shall:
                <ol>
                  <li>insure such goods to their full market value;</li>
                  <li>
                    store or otherwise identify such goods as to show that they
                    retain the property of the Company;
                  </li>
                  <li>
                    not mortgage, charge or otherwise encumber or dispose of the
                    goods (other than by selling them or incorporating them in
                    the usual course of business) without the written permission
                    of the Company.
                  </li>
                </ol>
              </li>
              <li>
                Where before payment in full of the sums due in clause 7(b) the
                Customer incorporates or resells such goods, it shall keep the
                proceeds of any sale in a non-overdrawn account and hold such
                proceeds on trust for the Company as fiduciary.
              </li>
              <li>
                The Customer agrees that if the Company invokes its right to
                repossession of its goods under clause 4(f)(v) the Company shall
                not be liable for any damage or injury reasonably done in the
                course of so doing to any other property owned by or in
                possession of the Customer to which the Company’s goods have
                been attached or in which they have been incorporated.
              </li>
              <li>
                Cutters or other tooling will remain the property of the Company
                whether or not it is charged for under the contract.
              </li>
            </ol>
            <li>
              <strong>Returns</strong>
            </li>
            <ol>
              <li>
                Returns for credit of goods supplied in accordance with the
                Customer’s order is at the sole discretion of the Company.
              </li>
              <li>
                Goods designed or produced to the special order of the Customer
                cannot be returned for credit, nor may such orders be cancelled
                by the Customer once production has begun.
              </li>
              <li>
                Return of goods is subject to a handling charge as determined by
                the Company and payable by the Customer in accordance with
                Clause 4(b) and subject to any or all the remedies under Clause
                4(f).
              </li>
            </ol>
            <li>
              <strong>Claims</strong>
            </li>
            <ol>
              <li>
                No claim for defect, damage in transit, short delivery or any
                other deficiency may be made unless such deficiency is notified
                in writing within 10 days of delivery or, for claims for
                non-delivery, within 10 days of the expected delivery date.
              </li>
              <li>
                In the absence of any such notification within the specified
                periods the Customer shall be deemed to have accepted the goods.
              </li>
              <li>
                The Company shall not be liable for any error present in a proof
                provided to the Customer but not corrected in proof by the
                Customer.
              </li>
              <li>
                The Customer agrees that if it uses any part of a consignment of
                goods it is deemed to have accepted that delivery and to have
                waived any claim or right to claim against the Company in
                respect of it.
              </li>
              <li>
                Where goods are notified to the Company as defective the
                Customer must return them to the Company if requested.
              </li>
            </ol>
            <li>
              <strong>Warranties and Liability</strong>
            </li>
            <ol>
              <li>
                The Company warrants that goods will correspond to their
                description as at the time of confirmation of order and that the
                production of goods will be carried out with reasonable care and
                skill and to a good standard of quality.
              </li>
              <li>
                The Company will replace, re-work or give credit to the Customer
                for any goods which do not comply with the warranty at clause
                10(a) and which are accepted by the Company for replacement,
                re-work or credit.
              </li>
              <li>
                Other than as set out in clause 10(a) or as expressly confirmed
                in writing by the Company:
                <ol>
                  <li>
                    no warranty condition or representation express or implied
                    as to description, quality or suitability of any goods
                    hereby sold is given by the Company or deemed to have been
                    given or implied and (to the extent permitted by statute)
                    any statutory or other warranty condition or representation
                    whether express or implied is hereby excluded;
                  </li>
                  <li>
                    the Company accepts no liability whatsoever for any loss or
                    damage whether consequential or direct and whether suffered
                    by or occasioned to the Customer the employees or agents of
                    the Customer or a third party which may arise after the
                    delivery of the goods.
                  </li>
                </ol>
              </li>
            </ol>
            <li>
              <strong>Intellectual Property Rights</strong>
            </li>
            <ol>
              <li>
                Unless agreed otherwise in writing by both Company and Customer,
                any design, drawing, specification or other document (in written
                or electronic form) prepared or produced by the Company shall
                remain the exclusive property of the Company and any request for
                supply of such items to the Customer shall be chargeable at the
                Company’s prevailing studio rates.
              </li>
              <li>
                The Customer shall be solely responsible for obtaining any and
                all necessary permissions or licences for the reproduction of
                artwork protected by copyright, trade mark or any other
                intellectual property right.
              </li>
              <li>
                The Company reserves the right to refuse to print material
                which, in its sole opinion, is or may be illegal, defamatory or
                infringe upon a third party’s intellectual property rights.
              </li>
              <li>
                The Customer agrees to indemnify the Company in respect of any
                claim from a third party, or any fine or other financial
                sanction, arising from the design or production of any goods to
                a specification provided by the Customer. Such indemnity to
                include the full extent of any fine, damages or costs for which
                the Company is held liable, any settlement entered into by the
                Company, and the Company’s own legal costs and other expenses
                incurred in responding to or defending such a claim.
              </li>
            </ol>
            <li>
              <strong>Force Majeure</strong>
            </li>
            <p>
              In the event of war, invasion, act of foreign enemy, hostilities
              (whether war has been declared or not), civil war, rebellion,
              revolution, military or usurped power, act of God, force majeure,
              epidemic or any other matter or occurrence beyond the control of
              the Company or the failure on the Part of the Customer to make due
              and timely supply of all materials and/or data and specifications
              as may be required and agreed as terms of the acceptance of any
              order by the Company, the Company shall be relieved of all
              liabilities incurred under the contract wherever and to the extent
              to which the fulfilment of such obligations is prevented,
              frustrated or impeded as a consequence of any such event or by the
              Statute Rules regulations Orders or Requisitions issued by any
              Government Department Council or other duty constituted authority
              or from strikes, lock-outs or other withdrawal of labour force,
              breakdown of plant or any other causes (whether or not of a like
              nature) beyond the Company's control.
            </p>
            <li>
              <strong>Law and Jurisdiction</strong>
            </li>
            <ol>
              <li>
                The contract is deemed to be made under and in accordance with
                English law.
              </li>
              <li>
                Any dispute under the contract shall be subject to the exclusive
                jurisdiction of the Courts of England and Wales
              </li>
            </ol>
          </ol>
        </div>
      </div>

      <div className="c-footer">
        <div className="c-footer-inner">
          <h2>What people say about us</h2>
          <div className="tp-widget-wrapper">
            <img alt="" src="/head/about.png" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Terms;
