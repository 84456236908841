import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import ProductDetailTable from "./ProductDetailTable";

const ProductDetail = () => {
  const { addToCart } = useContext(CartContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [quantities, setQuantities] = useState("1");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedAdhesive, setSelectedAdhesive] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const product = productData.find((item) => item.id === parseInt(id));

  const hasAdhesive = product?.adhesive && product.adhesive.length > 0;
  const hasSize = product?.size && product.size.length > 0;
  const hasColor = product?.color && product.color.length > 0;

  const handleAddToCart = () => {
    if (hasColor && !selectedColor) {
      alert("Please select a color");
      return;
    }
    if (hasSize && !selectedSize) {
      alert("Please select a size");
      return;
    }
    if (hasAdhesive && !selectedAdhesive) {
      alert("Please select an adhesive");
      return;
    }

    const cartItem = {
      id: product.id,
      quantity: parseInt(quantities, 10),
      color: hasColor ? selectedColor : undefined,
      size: hasSize ? selectedSize : undefined,
      adhesive: hasAdhesive ? selectedAdhesive : undefined,
    };

    addToCart(cartItem);
    navigate("/cartpage");
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="shopDiv-inner">
          <p className="detail-title">Home / {product.name}</p>
          <h1 className="page-title">{product.name}</h1>
          <div className="product-detail">
            <div className="product-images">
              <div className="carousel">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${product.name}`}
                    style={{
                      display: index === currentIndex ? "block" : "none",
                    }}
                  />
                ))}
              </div>
              <div className="carousel-buttons">
                {product.images.map((image, index) => (
                  <button key={index} onClick={() => setCurrentIndex(index)}>
                    <img src={image} alt={`${product.name}`} />
                  </button>
                ))}
              </div>
            </div>
            <div className="product-info">
              <h2 className="startPrice">
                £{product.price}&nbsp;
                <span class="per-pack-text">&nbsp;(Incl.VAT) per pack</span>
              </h2>
              <div className="des-partno">
                <Link to={`/shopDetails/${product.id}`} className="view-des">
                  View product description
                </Link>
                <strong className="u-text-smaller">
                  Part No: {product.partNo}
                </strong>
              </div>

              {(hasColor || hasSize || hasAdhesive) && (
                <div className="product-options-wrapper">
                  {hasColor && (
                    <div className="color-options">
                      <label className="color-options-label">
                        Select a colour
                      </label>
                      <div className="color-all">
                        {product.color.map((color, index) => (
                          <div
                            key={index}
                            className="color-option"
                            style={{
                              backgroundColor: color,
                              boxShadow:
                                color === selectedColor
                                  ? "0px 0px 6px 2px rgba(0, 0, 0, 0.3)"
                                  : "none",
                            }}
                            onClick={() => setSelectedColor(color)}
                          ></div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="size-adhesive">
                    {hasSize && (
                      <div className="size-options">
                        <label className="color-options-label">Size:</label>
                        <br />
                        <select
                          value={selectedSize}
                          onChange={(e) => setSelectedSize(e.target.value)}
                        >
                          <option value="">Choose an Option...</option>
                          {product.size.map((size, index) => (
                            <option key={index} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {hasAdhesive && (
                      <div className="adhesive-options">
                        <label className="color-options-label">Adhesive:</label>
                        <br />
                        <select
                          value={selectedAdhesive}
                          onChange={(e) => setSelectedAdhesive(e.target.value)}
                        >
                          <option value="">Choose an Option...</option>
                          {product.adhesive.map((adhesive, index) => (
                            <option key={index} value={adhesive}>
                              {adhesive}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="fieldset">
                <div className="box-tocart">
                  <div className="field-qty">
                    <label class="qty-label" for="qty">
                      Qty
                    </label>
                    <br />
                    <input
                      type="number"
                      value={quantities}
                      min="1"
                      onChange={(e) =>
                        setQuantities(Math.max(1, parseInt(e.target.value, 10)))
                      }
                      className="quantity-input"
                    />
                  </div>
                  <div className="basketDiv">
                    <button
                      className="add-to-cart"
                      onClick={() => handleAddToCart(product.id, quantities)}
                    >
                      Add to Basket
                    </button>
                  </div>
                </div>
              </div>
              <div className="des__box">
                <p>{product.description}</p>
              </div>
            </div>
          </div>
          <ProductDetailTable />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
