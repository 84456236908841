import "../css/header.css";
import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { Link } from "react-router-dom";

function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    navigate("/cartpage");
  };
  const toMain = () => {
    navigate("/");
  };
  const { cartItems } = useContext(CartContext);

  return (
    <div className="header-container">
      <div className="panel">
        <div className="panel__inner">
          <div className="inner-left">
            <h3>Excellent</h3>
            <div className="start-box">
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/forma-light-filled/24/00b67a/rating.png"
                alt="rating"
              />
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/forma-light-filled/24/00b67a/rating.png"
                alt="rating"
              />
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/forma-light-filled/24/00b67a/rating.png"
                alt="rating"
              />
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/forma-light-filled/24/00b67a/rating.png"
                alt="rating"
              />
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/forma-light-filled/24/00b67a/rating.png"
                alt="rating"
              />
            </div>
            <p>
              <strong>2024</strong> reviews on
            </p>
            <p>
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/ios-glyphs/50/00b67a/star--v1.png"
                alt="star--v1"
              />
            </p>
            <p>Trustpilot</p>
          </div>
          <div className="inner-right">
            <ul>
              <li>
                <Link to="/">Label Designer</Link>
              </li>
              <li>
                <Link to="/">Eco Friendly Labels</Link>
              </li>
              <li>
                <Link to="/">Shop by product code</Link>
              </li>
            </ul>
            <p>GBP (£)</p>
          </div>
        </div>
      </div>
      <div className="allDiv">
        <div className="headerDiv">
          <div className="left" onClick={toMain}>
            <img src="/head/head01.svg" alt="brand" />
          </div>
          <div className="center">
            <Link to="/">Home</Link>
            <Link to="/aboutUs">About Us</Link>
            <Link to="/privacy">Privacy</Link>
          </div>
          <div className="right" onClick={toggleSidebar}>
            <FontAwesomeIcon
              icon={faBasketShopping}
              size="2xl"
              color="#4f4d4d"
            />
            <div className="cart-count">{cartItems.length}</div>
          </div>
        </div>
      </div>
      <div className="firstTop">
        <div className="firstLeft">
          <img alt="" src="/head/truck.svg" />
          <span>
            <strong>Free Delivery on Orders Over £150</strong>UK Mainland
          </span>
        </div>
        <div className="firstCenter">
          <img alt="" src="/head/cube.svg" />
          <span>
            <strong>Same Day Dispatch</strong> Orders Before 3pm*
          </span>
        </div>
        <div className="firstRight">
          <img alt="" src="/head/pound.svg" />
          <span>
            <strong>Price Match Guarantee</strong> Lowest Prices
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
