import React from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import "../css/productdetails.css";

const ProductDetailTable = () => {
  const { id } = useParams();
  const product = productData.find((item) => item.id === parseInt(id));

  const renderTableBody = () => {
    if (product.cateId === 1 || product.cateId === 6) {
      return (
        <>
          <tr>
            <td>Size</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Print Colour(s)</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[4]}</td>
          </tr>
          <tr>
            <td>Shape</td>
            <td>{product.prInformation[5]}</td>
          </tr>
        </>
      );
    } else if (product.cateId === 2 || product.cateId === 3) {
      return (
        <>
          <tr>
            <td>Adhesive</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[4]}</td>
          </tr>
          <tr>
            <td>Lead Time</td>
            <td>{product.prInformation[5]}</td>
          </tr>
        </>
      );
    } else if (product.cateId === 4 || product.cateId === 8) {
      return (
        <>
          <tr>
            <td>Adhesive</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Print Colour(s)</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[4]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[5]}</td>
          </tr>
        </>
      );
    } else if (product.cateId === 5 || product.id === 26) {
      return (
        <>
          <tr>
            <td>Adhesive</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Print Colour(s)</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[4]}</td>
          </tr>
        </>
      );
    } else if (product.cateId === 7) {
      return (
        <>
          <tr>
            <td>Adhesive</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Core Size</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[4]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[5]}</td>
          </tr>
        </>
      );
    } else if (product.id === 22) {
      return (
        <>
          <tr>
            <td>Size</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Labels Per Sheet</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Avery Compatibility</td>
            <td>{product.prInformation[4]}</td>
          </tr>
        </>
      );
    } else if (product.id === 23) {
      return (
        <>
          <tr>
            <td>Adhesive</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Print Colour(s)</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Shape</td>
            <td>{product.prInformation[4]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[5]}</td>
          </tr>
        </>
      );
    } else if (product.id === 24) {
      return (
        <>
          <tr>
            <td>Adhesive</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>{product.prInformation[3]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[4]}</td>
          </tr>
          <tr>
            <td>Pack Size</td>
            <td>{product.prInformation[5]}</td>
          </tr>
          <tr>
            <td>Width</td>
            <td>{product.prInformation[6]}</td>
          </tr>
          <tr>
            <td>Shape</td>
            <td>{product.prInformation[7]}</td>
          </tr>
        </>
      );
    } else if (product.id === 25) {
      return (
        <>
          <tr>
            <td>Adhesive</td>
            <td>{product.prInformation[0]}</td>
          </tr>
          <tr>
            <td>Print Colour(s)</td>
            <td>{product.prInformation[1]}</td>
          </tr>
          <tr>
            <td>Base Colour</td>
            <td>{product.prInformation[2]}</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>{product.prInformation[3]}</td>
          </tr>
        </>
      );
    }
    return null;
  };

  return (
    <div className="product-information">
      <p>Product Information</p>
      <div className="info-div">
        <div className="info-div-inner">
          <h6>{product.description}</h6>
          <div className="u-bordered-padded spec-table">
            <table className="table--stripped-alt table--content ">
              <thead>
                <tr>
                  <th colspan="2">Specifications</th>
                </tr>
              </thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailTable;
