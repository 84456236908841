import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "../css/main.css";
import productData from "../Datas/productData";
import Category from "./Category";

function Main() {
  const [isShopContentVisible, setIsShopContentVisible] = useState(false);

  const toggleShopContentVisibility = () => {
    setIsShopContentVisible(!isShopContentVisible);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img src="/head/101.jpg" alt="twoImg" />
        </div>
        <Category />
        <div className="fourDiv">
          <div className="fourDiv-inner">
            <h2>Design & Print Service</h2>
            <p>
              Custom Printed Self-Adhesive Labels, Stickers & Tags To Your
              Requirements
            </p>
            <div className="link-a">
              <Link to="/contact">Contact Us Today</Link>
            </div>
            <p>Contact Us Today For a Quick No Obligation Quote</p>
            <h3>0800 91 71 301</h3>
          </div>
        </div>
        <div className="fiveDiv">
          <h2>Popular Ranges This Week</h2>
          <ul className="brand-shops">
            {productData
              .filter((brand) => brand.id >= 22 && brand.id <= 26)
              .map((brand, index) => (
                <li key={index}>
                  <Link to={`/shopDetails/${brand.id}`}>
                    <img alt="icon" src={brand.images[0]} />
                  </Link>
                  <h5>{brand.title}</h5>
                  <p>{brand.main}</p>
                </li>
              ))}
          </ul>
        </div>

        <div className="c-ourcustomers__wrapper">
          <div className="c-ourcustomers__wrapper-inner">
            <h2>Our Customers Include</h2>
            <ul>
              <li>
                <img alt="" src="/head/customer1.png" />
              </li>
              <li>
                <img alt="" src="/head/customer2.png" />
              </li>
              <li>
                <img alt="" src="/head/customer3.png" />
              </li>
              <li>
                <img alt="" src="/head/customer4.png" />
              </li>
              <li>
                <img alt="" src="/head/customer5.png" />
              </li>
              <li>
                <img alt="" src="/head/customer6.png" />
              </li>
            </ul>
          </div>
        </div>

        <div className="shop-img">
          <div className="title-wrapper">
            <div className="title-wrapper-inner">
              <h2 onClick={toggleShopContentVisibility}>Why Shop With Us?</h2>
            </div>
          </div>
          {isShopContentVisible && (
            <div className="shop-content">
              <div className="shop-content-inner">
                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop1.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">
                    ISO 9001:2015 Accreditation
                  </div>
                </div>
                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop2.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">Worldwide Delivery</div>
                </div>
                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop3.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">
                    Exceptional Customer Service
                  </div>
                </div>

                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop4.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">
                    Trusted UK Manufacturer
                  </div>
                </div>
                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop5.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">
                    Free Delivery on Orders Over £150
                  </div>
                </div>
                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop6.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">Custom Labels</div>
                </div>

                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop7.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">
                    Public Sector Accounts Welcome
                  </div>
                </div>
                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop7.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">
                    Lowest Prices Guaranteed
                  </div>
                </div>
                <div className="c-whyshop__item">
                  <div className="c-whyshop__icon-wrapper">
                    <img
                      alt=""
                      src="/head/shop8.png"
                      className="c-whyshop__icon-image"
                    />
                  </div>
                  <div className="c-whyshop__label">Sample Packs</div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="c-footer">
          <div className="c-footer-inner">
            <h2>What people say about us</h2>
            <div className="tp-widget-wrapper">
              <img alt="" src="/head/about.png" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
