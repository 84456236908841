import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    setCartItems((prevItems) => {
      const { id, quantity, color, size, adhesive } = item;
      const existingItem = prevItems.find(
        (i) =>
          i.id === id &&
          i.color === color &&
          i.size === size &&
          i.adhesive === adhesive
      );

      if (existingItem) {
        return prevItems.map((i) =>
          i.id === id &&
          i.color === color &&
          i.size === size &&
          i.adhesive === adhesive
            ? { ...i, quantity: i.quantity + quantity }
            : i
        );
      } else {
        const newItem = { id, quantity, color, size, adhesive };
        return [...prevItems, newItem];
      }
    });
  };

  const removeFromCart = (item) => {
    const { id, color, size, adhesive } = item;
    setCartItems((prevItems) =>
      prevItems.filter(
        (i) =>
          i.id !== id ||
          i.color !== color ||
          i.size !== size ||
          i.adhesive !== adhesive
      )
    );
  };

  const updateQuantity = (item) => {
    const { id, color, size, adhesive, quantity } = item;
    setCartItems((prevItems) =>
      prevItems.map((i) =>
        i.id === id &&
        i.color === color &&
        i.size === size &&
        i.adhesive === adhesive
          ? { ...i, quantity }
          : i
      )
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
