const productData = [
  {
    id: 1,
    cateId: 1,
    name: "Oval Price Labels 19x14mm",
    description:
      "Oval Price Labels 19x14mm are available in a range of price points and permanent or removable adhesive.",
    price: "1.20",
    partNo: "CP0364",
    adhesive: ["Permanent", "Removable"],
    size: [
      "10p",
      "20p",
      "30p",
      "40p",
      "50p",
      "60p",
      "80p",
      "90p",
      "1£",
      "1.2£",
      "1.3£",
      "1.4£",
    ],
    prInformation: [
      "100mm Diameter",
      "Black",
      "Yellow",
      "Paper",
      "72 Labels",
      "Oval",
    ],
    images: ["/images/1001.png", "/images/1001a.jpg"],
  },
  {
    id: 2,
    cateId: 1,
    name: "Yellow 20mm Price Labels",
    description:
      "Yellow 20mm Price Labels are available in a range of price points and permanent or removable adhesive.",
    price: "3.30",
    partNo: "CP0559",
    adhesive: ["Permanent", "Removable"],
    size: [
      "55p",
      "59p",
      "95p",
      "99p",
      "£1.15",
      "£5.99",
      "£7.99",
      "£9.99",
      "£12.99",
      "£14.99",
      "2 for 99p",
    ],
    prInformation: [
      "20mm Diameter",
      "Red",
      "White",
      "Paper",
      "126 Labels",
      "Circle",
    ],
    images: ["/images/1002.png", "/images/1002a.jpg"],
  },
  {
    id: 3,
    cateId: 1,
    name: "Red 20mm Price Labels",
    description:
      "Red 20mm Price Labels are available in a range of price points and permanent or removable adhesive.",
    price: "3.0",
    partNo: "CP0420",
    adhesive: ["Permanent", "Removable"],
    size: [
      "10p",
      "20p",
      "30p",
      "40p",
      "50p",
      "60p",
      "80p",
      "90p",
      "1£",
      "1.2£",
      "1.3£",
      "1.4£",
    ],
    prInformation: [
      "20mm Diameter",
      "White",
      "Red",
      "Paper",
      "126 Labels",
      "Circle",
    ],
    images: ["/images/1003.png", "/images/1003a.jpg"],
  },
  {
    id: 4,
    cateId: 1,
    name: "Yellow 25mm Price Labels",
    description:
      "Yellow 25mm Price Labels are available in a range of price points and permanent or removable adhesive.",
    price: "5.40",
    partNo: "CP0560",
    adhesive: ["Permanent", "Removable"],
    size: ["50p", "690p", "89p", "99p", "1.29£", "1.49£", "1.99£"],
    prInformation: [
      "25mm Diameter",
      "Black",
      "Yellow",
      "Paper",
      "500 Labels",
      "Circle",
    ],
    images: ["/images/1004.png", "/images/1004a.jpg"],
  },

  {
    id: 5,
    cateId: 2,
    name: "Budget Polyester Asset Labels Number 30x15mm",
    description:
      "Budget Polyester Asset Labels Number 30x15mm are available in a choice of colours.",
    price: "60.00",
    partNo: "CP0129",
    color: ["#f50e0e", "#0000ff", "#000000", "#127a12"],
    prInformation: [
      "Permanent",
      "38x15mm",
      "White",
      "Polyester",
      "100 Labels",
      "5-7 Days",
    ],
    images: [
      "/images/1005.jpg",
      "/images/1005a.jpg",
      "/images/1005b.jpg",
      "/images/1005c.jpg",
      "/images/1005d.jpg",
    ],
  },
  {
    id: 6,
    cateId: 2,
    name: "Budget Polyester Asset Labels Barcode 30x15mm",
    description:
      "Budget Polyester Asset Labels Barcode 30x15mm are available in a choice of colours.",
    price: "60.00",
    partNo: "CP0125",
    color: ["#f50e0e", "#0000ff", "#000000", "#127a12"],
    prInformation: [
      "Permanent",
      "30x15mm",
      "White",
      "Polyester",
      "100 Labels",
      "5-7 Days",
    ],
    images: [
      "/images/1006.jpg",
      "/images/1006a.jpg",
      "/images/1006b.jpg",
      "/images/1006c.jpg",
      "/images/1006d.jpg",
    ],
  },
  {
    id: 7,
    cateId: 3,
    name: "3rd Edition Plug Top PAT Test Labels 25x25mm",
    description:
      "3rd Edition Plug Top PAT Test Labels 25x25mm are available in a choice of colours & supplied in packs of 100.",
    price: "3.90",
    partNo: "CP0040",
    color: ["#f50e0e", "#0000ff", "#000000", "#127a12"],
    prInformation: [
      "Permanent",
      "25x25mm",
      "White",
      "Matt Vinyl",
      "100 Labels",
      "5-7 Days",
    ],
    images: [
      "/images/1007.jpg",
      "/images/1007a.jpg",
      "/images/1007b.jpg",
      "/images/1007c.jpg",
      "/images/1007d.jpg",
    ],
  },
  {
    id: 8,
    cateId: 3,
    name: "Personalised 3rd Edition PAT Test Passed Labels 50x25mm",
    description:
      "Personalised 3rd Edition PAT Test Passed Labels 50x25mm are available in a choice of colours.",
    price: "10.20",
    partNo: "CP0375",
    color: ["#0000ff", "#127a12", "#000000", "#800080"],
    prInformation: [
      "Permanent",
      "50x25mm",
      "White",
      "Matt Vinyl",
      "250 Labels",
      "5-7 Days",
    ],
    images: [
      "/images/1008.jpg",
      "/images/1008a.jpg",
      "/images/1008b.jpg",
      "/images/1008c.jpg",
      "/images/1008d.jpg",
    ],
  },
  {
    id: 9,
    cateId: 4,
    name: "No Smoking Stickers 150x43mm",
    price: "11.10",
    partNo: "NS1026",
    prInformation: [
      "Permanent",
      "150x43mm",
      "Red & Black",
      "White",
      "Vinyl",
      "25 Labels",
    ],
    images: ["/images/1009.jpg"],
  },
  {
    id: 10,
    cateId: 4,
    name: "No Smoking Stickers 75x100mm",
    price: "11.10",
    partNo: "NS1012",
    prInformation: [
      "Permanent",
      "75x100mm",
      "Red & Black",
      "White",
      "Vinyl",
      "25 Labels",
    ],
    images: ["/images/10010.jpg"],
  },
  {
    id: 11,
    cateId: 5,
    name: "Falling Ice Spikes Warning Labels",
    description:
      "Falling Ice Spikes Warning Labels are supplied in packs of 10 labels and available in a choice of sizes.",
    price: "4.80",
    partNo: "CP0241",
    size: ["25x25mm", "50x50mm", "100x100mm"],
    prInformation: ["Permanent", "Black", "Yellow", "Vinyl", "10 Labels"],
    images: ["/images/10011.png"],
  },
  {
    id: 12,
    cateId: 5,
    name: "Corrosive Substance Warning Labels",
    description:
      "Corrosive Substance Warning Labels are supplied in packs of 10 labels and available in a choice of sizes.",
    price: "4.80",
    partNo: "CP0176",
    size: ["25x25mm", "50x50mm", "100x100mm"],
    prInformation: ["Permanent", "Black", "Yellow", "Vinyl", "10 Labels"],
    images: ["/images/10012.png"],
  },
  {
    id: 13,
    cateId: 5,
    name: "Slippery Surface Warning Labels",
    description:
      "Slippery Surface Warning Labels are supplied in packs of 10 labels and available in a choice of sizes.",
    price: "4.80",
    partNo: "CP0481",
    size: ["25x25mm", "50x50mm", "100x100mm"],
    prInformation: ["Permanent", "Black", "Yellow", "Vinyl", "10 Labels"],
    images: ["/images/10013.png"],
  },
  {
    id: 14,
    cateId: 6,
    name: "Food Allergen Labels 50x50mm",
    description:
      "Food Allergen Labels Measure 50x50mm, supplied on rolls of 500 and available with a choice of adhesive.",
    price: "3.90",
    partNo: "CP0251",
    adhesive: ["Permanent", "Removable"],
    prInformation: [
      "50x50mm",
      "Purple",
      "White",
      "Paper",
      "500 Labels",
      "Square",
    ],
    images: ["/images/10014.png", "/images/10014a.png"],
  },

  {
    id: 15,
    cateId: 6,
    name: "Food Allergen Labels 76x51mm",
    description:
      "Food Allergen Labels Measure 76x51mm, supplied on rolls of 500 and available with a choice of adhesive.",
    price: "8.10",
    partNo: "CP0252",
    adhesive: ["Permanent", "Removable"],
    prInformation: [
      "76x51mm",
      "Purple",
      "White",
      "Paper",
      "500 Labels",
      "Rectangle",
    ],
    images: ["/images/10015.png", "/images/10015a.jpg"],
  },
  {
    id: 16,
    cateId: 7,
    name: "102x149mm Direct Thermal Paper Labels Permanent",
    description: "",
    price: "102.00",
    partNo: "TD1003",
    prInformation: [
      "Permanent",
      "100mm Diameter",
      "White",
      "38mm",
      "Thermal Direct Paper",
      "5000 Labels",
    ],
    images: ["/images/10016.png"],
  },
  {
    id: 17,
    cateId: 7,
    name: "Fragile Labels 75x100mm",
    description:
      "Self-Adhesive Fragile Labels measure 75x100mm & are supplied on rolls of 500.",
    price: "10.80",
    partNo: "PA1067",
    prInformation: [
      "Permanent",
      "75x100mm",
      "Red",
      "White",
      "Paper",
      "500 Labels",
    ],
    images: ["/images/10017.png", "/images/10017a.png"],
  },
  {
    id: 18,
    cateId: 8,
    name: "CE Labels 10x10mm",
    description:
      "CE Labels measure 10x10mm, supplied in packs of 100 and suitable for internal and external use.",
    price: "6.90",
    partNo: "EL1369",
    prInformation: [
      "Permanent",
      "10x10mm",
      "Black",
      "White",
      "Vinyl",
      "100 Labels",
    ],
    images: ["/images/10018.jpg"],
  },
  {
    id: 19,
    cateId: 8,
    name: "WEEE Labels 10x13mm",
    description:
      "WEEE Labels measure 10x13mm, produced on permanent vinyl & supplied in packs of 500. ",
    price: "9.00",
    partNo: "EL1387",
    prInformation: [
      "Permanent",
      "10x13mm",
      "Black",
      "White",
      "Vinyl",
      "500 Labels",
    ],
    images: ["/images/10019.jpg"],
  },
  {
    id: 20,
    cateId: 8,
    name: "WEEE & CE Labels 50x25mm",
    description:
      "WEEE & CE Labels measure 50x25mm, produced on permanent vinyl & supplied in packs of 500.",
    price: "24.00",
    partNo: "EL1393",
    prInformation: [
      "Permanent",
      "50x25mm",
      "Black",
      "White",
      "Vinyl",
      "500 Labels",
    ],
    images: ["/images/10020.jpg"],
  },
  {
    id: 21,
    cateId: 8,
    name: "Tester Stickers Black on White 15mm",
    description:
      "Tester Stickers are printed black on white vinyl, measure 15mm in diameter and supplied in packs of 120.",
    price: "8.10",
    partNo: "PH1031",
    prInformation: [
      "Permanent",
      "15mm Diameter",
      "Black",
      "White",
      "Vinyl",
      "120 Labels",
    ],
    images: ["/images/10021.jpg"],
  },
  {
    id: 22,
    title: "Label Sheets",
    name: "A4 Sheet Labels 1 Label Per Sheet",
    main: "Our A4 Label Sheets are available in a range of shapes, sizes, and colours.",
    description:
      "1 Up 200x289mm A4 Label Sheets are available in a choice of colours and adhesive options.",
    price: "24.00",
    partNo: "CP0084",
    adhesive: ["Permanent", "Removable"],
    color: ["#ffd608", "#f50e0e", "#fff", "#0000ff", "#127a12"],
    prInformation: [
      "200x289mm",
      "Laser & Inkjet Compatible Paper",
      "100 Sheets",
      "1",
      "L7167, J8167",
    ],
    images: ["/images/10022.png"],
  },
  {
    id: 23,
    title: "Handmade Stickers",
    name: "Handmade with Love Heart Stickers 30x30mm",
    main: "View our range of environmentally-friendly Handmade Stickers.",
    description:
      "Self-Adhesive Handmade with Love Stickers printed black on gloss white recyclable paper with a permanent adhesive. Stickers measure 30x30mm in packs of 100",
    price: "2.40",
    partNo: "HM0012",
    prInformation: [
      "Permanent",
      "Black",
      "White",
      "Paper",
      "Heart",
      "100 Labels",
    ],
    images: ["/images/10023.png"],
  },
  {
    id: 24,
    title: "Ladder Inspection Labels",
    name: "Ladder Safety Inspection Label 50x80mm",
    main: "Ensure compliance with WAHR 2005 regulations with our Ladder Safety Labels.",
    description:
      "Ladder Safety Inspection Labels measure 50x80mm and supplied in packs of 50 labels.",
    price: "7.80",
    partNo: "TH1174",
    prInformation: [
      "Permanent",
      "100mm Diameter",
      "White",
      "80mm",
      "Vinyl",
      "50",
      "50mm",
      "	Rectangle",
    ],
    images: ["/images/10024.png"],
  },
  {
    id: 25,
    title: "Limited Quantity Labels",
    name: "Limited Quantity for Road & Sea Labels and Placards",
    main: "Shop our range of ADR, RID, IMDG & IATA compliant Limited Quantity Labels.",
    description:
      "Ladder Safety Inspection Labels measure 50x80mm and supplied in packs of 50 labels.",
    price: "0.48",
    partNo: "CP0304",
    size: ["50x50mm", "100x100mm", "250x250mm"],
    prInformation: ["Permanent", "Black", "White", "Gloss White Polypropylene"],
    images: ["/images/10025.png", "/images/10025a.jpg"],
  },
  {
    id: 26,
    title: "Eco-Friendly Labels",
    name: "100% Recyclable Packaging Labels",
    main: "Biodegradable, Plastic Free, and Recyclable Eco-Friendly Labels.",
    description:
      "100% Recyclable Packaging Labels are available in a choice of sizes and supplied on rolls of 500.",
    price: "9.00",
    partNo: "CP0012",
    size: ["30mm Diameter", "50mm Diameter"],
    prInformation: ["Permanent", "Green", "Red", "Paper", "500 Labels"],
    images: ["/images/10026.png", "/images/10026a.png"],
  },
];

export default productData;
