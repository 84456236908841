import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    navigate("/checkout");
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };
  const calculateTotal1 = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity + item.quantity * 0.2;
    }, 0);
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price.replace("£", ""));
    return price * quantity + quantity * 0.2; // 计算总价
  };

  const handleInputChange = (item, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      const { id, color, size, adhesive } = item;
      updateQuantity({ id, color, size, adhesive, quantity: newQuantity });
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="basket-title">
            <h1>Shopping Basket</h1>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="left">Price</TableCell>
                        <TableCell align="center">Qty</TableCell>
                        <TableCell align="left">Subtotal</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <TableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <StyledImage
                                src={product.images[0]}
                                alt="Product"
                                style={{
                                  paddingRight: "30px",
                                }}
                              />
                              <div
                                style={{
                                  display: "grid",
                                }}
                              >
                                {product.name}
                                <br />
                                {item.adhesive && (
                                  <span>
                                    <strong>Adhesive:</strong>&nbsp;
                                    {item.adhesive}
                                  </span>
                                )}

                                {item.size && (
                                  <span>
                                    <strong>Size:</strong>&nbsp;
                                    {item.size}
                                  </span>
                                )}
                                {item.color && (
                                  <span>
                                    <strong>Color:</strong>&nbsp;
                                    {item.color}
                                  </span>
                                )}
                                <span>
                                  <strong>Part No:</strong>&nbsp;
                                  {product.partNo}
                                </span>
                              </div>
                            </StyledTableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "1px dotted #ccc",
                              }}
                            >
                              £{product.price}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <input
                                type="number"
                                value={item.quantity || 1}
                                className="cartInput"
                                onChange={(e) => handleInputChange(item, e)}
                                min="0"
                              />
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ verticalAlign: "middle" }}
                            >
                              £
                              {calculateItemTotal(
                                item.id,
                                item.quantity
                              ).toFixed(2)}
                              <a
                                onClick={() =>
                                  removeFromCart({
                                    id: item.id,
                                    color: item.color,
                                    size: item.size,
                                    adhesive: item.adhesive,
                                  })
                                }
                                style={{
                                  cursor: "context-menu",
                                  textDecoration: "underline",
                                  fontFamily: "Libre Baskerville",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  marginLeft: "20%",
                                  display: "flex",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  width="15"
                                  height="15"
                                  src="https://img.icons8.com/ios/50/delete--v1.png"
                                  alt="delete--v1"
                                />
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <h2 className="basketDiv">Order Summary</h2>

                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh">Subtotal(Excl. Tax)</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>

                        <tr className="sumTr">
                          <th className="subtotalTh">VAT</th>
                          <th className="subtotalTxt">0.20/Qty</th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalTh1">Order Total</th>
                          <th className="subtotalTxt1">
                            £{calculateTotal1().toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <button className="checkout-button" onClick={toCheckOut}>
                      Proceed to checkout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
